import Send from '../../cp.client.js'

export default {
    mainList (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/mainList',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                territory: param.territory,
                route: param.route,
                sales: param.sales,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                polCtr: param.polCtr,
                polPlc: param.polPlc,
                podCtr: param.podCtr,
                podPlc: param.podPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                routeYn: param.routeYn,
                porCtrYn: param.porCtrYn,
                porPlcCdYn: param.porPlcCdYn,
                polCtrYn: param.polCtrYn,
                polPlcCdYn: param.polPlcCdYn,
                podCtrYn: param.podCtrYn,
                podPlcCdYn: param.podPlcCdYn,
                dlyCtrYn: param.dlyCtrYn,
                dlyPlcCdYn: param.dlyPlcCdYn,
                vslCdYn: param.vslCdYn,
                voyNoYn: param.voyNoYn,
                solverGbCd: param.solverGbCd
            }
        })
    },
    detailList_1 (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/detailList_1',
            method: 'get',
            params: {
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc
            }
        })
    },
    lowYdSave (data) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/lowYdSave',
            method: 'put',
            data: data
        })
    },
    detailList_2 (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/detailList_2',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                sales: param.sales,
                porCtr: param.porCtr,
                polCtr: param.polCtr,
                podCtr: param.podCtr,
                dlyCtr: param.dlyCtr,
                porPlc: param.porPlc,
                polPlc: param.polPlc,
                podPlc: param.podPlc,
                dlyPlc: param.dlyPlc,
                vslCd: param.vslCd,
                voyNo: param.voyNo,
                rteCd: param.rteCd
            }
        })
    },
    bsaSave (data) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/bsaSave',
            method: 'put',
            data: data
        })
    },
    detailList_3 (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/detailList_3',
            method: 'get',
            params: {
                vslCd: param.vslCd,
                voyNo: param.voyNo,
                rteCd: param.rteCd,
                yearWeek: param.yearWeek
            }
        })
    },
    solver (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/solver',
            method: 'put',
            params: {
                solverPercent: param.solverPercent,
                solverParam: param.solverParam
            }
        })
    },
    iccSaveValid (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/iccSaveValid',
            method: 'put',
            params: {
                solverParam: param.solverParam
            }
        })
    },
    iccSave (param) {
        return Send({
            url: '/bsa/bsaAdjRvnTotal/iccSave',
            method: 'put',
            params: {
                solverParam: param.solverParam
            }
        })
    }
}
